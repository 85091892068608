import { POPUP_ID } from '@components/ProfileUpdateInfo/ProfileInformation';
import { clearUserInfo } from '@utils/auth-helper';
import Router from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { LOGIN_STATUS } from '../../constants';
import { getCurrentUser } from '../../services/user.service';
import { setInfoUserLogin } from '../../utils/auth-helper';
import { triggerEventRegistrationComplete } from '../../utils/gtmUtil';

const useAuth = () => {
  const signed = useSelector((state) => state.app.auth.signed);
  const user = useSelector((state) => state.app.user);
  const dispatchApp = useDispatch().app;
  const setUser = (data) => {
    dispatchApp.setLoginUser(data);
  };

  const setLoginStatus = (status) => {
    if (status === LOGIN_STATUS.NOT_LOGGED_IN) {
      const { gapi, FB } = window;
      // Sign out google
      if (gapi && gapi.auth2 && gapi.auth2.getAuthInstance().isSignedIn.get()) {
        window.gapi.auth2.getAuthInstance().signOut();
      }
      // Sign out facebook
      if (FB && FB.getAccessToken()) {
        FB.logout();
      }
      if (typeof window !== 'undefined') {
        window.google?.accounts?.id?.disableAutoSelect();
      }

      dispatchApp.setLoginUser({});
      dispatchApp.setSigned(LOGIN_STATUS.NOT_LOGGED_IN);
    }
  };

  const logout = () => {
    const { gapi, FB } = window;
    // Sign out google
    if (gapi && gapi.auth2 && gapi.auth2.getAuthInstance().isSignedIn.get()) {
      window.gapi.auth2.getAuthInstance().signOut();
    }
    // Sign out facebook
    if (FB && FB.getAccessToken()) {
      FB.logout();
    }

    dispatchApp.setLoginUser({});
    dispatchApp.setSigned(LOGIN_STATUS.NOT_LOGGED_IN);
    clearUserInfo();

    Router.push(Router.asPath === '/dashboard' ? '/' : Router.asPath);
  };

  return { signed, setSigned: setLoginStatus, logout, user, setUser };
};

const processAuthResponse = async (authenticationResponse) => {
  if (!authenticationResponse) return;
  const { token, expired } = authenticationResponse;
  setInfoUserLogin({ data: authenticationResponse });
  const resGetCurrentUser = await getCurrentUser();
  const roles = ((resGetCurrentUser.data || {}).roles || []).map((r) => r.code);
  setInfoUserLogin({
    data: {
      token,
      expired,
      roles,
      user: { ...(resGetCurrentUser.data || {}), roles },
    },
  });
};

const handleAfterLoginComplete = (dispatchApp, user, positionLogin, options = {}) => {
  processAuthResponse(user);
  triggerEventRegistrationComplete(user, positionLogin);
  dispatchApp.hiddenPopupLogin();
  if (user.registerNew) {
    dispatchApp.showPopup(POPUP_ID);
  }
  dispatchApp.initAuth();
};

export { handleAfterLoginComplete, processAuthResponse, useAuth };
