const getParts = (tradingAcademySlug) => {
  if (!tradingAcademySlug) return [];

  const partByAcademy = {
    'khoa-hoc-price-action-volume-master': [
      { 
        "code": "Các nền tảng khi phân tích với VOLUME", 
        "name": "Chương 1: Các nền tảng khi phân tích với VOLUME",
        "name_vi": "Chương 1: Các nền tảng khi phân tích với VOLUME",
        "name_en": "Chapter 1: The Basics of Volume Analysis ",
        "name_cn": "第 1 章：使用 VOLUME 进行分析的基础知识",
        isHaveTest: true,
        order: 1
      },
      { 
        "code": "Structure - Cấu trúc thị trường với Price Action & Volume", 
        "name": "Chương 2: [S]tructure - Cấu trúc thị trường với Volume",
        "name_vi": "Chương 2: [S]tructure - Cấu trúc thị trường với Volume",
        "name_en": "Chapter 2: [S]tructure - Market Structure and Trading Volume",
        "name_cn": "第 2 章：[S]tructure - 具有交易量的市场结构",
        isHaveTest: true,
        order: 2
      }, 
      { 
        "code": "Momentum - Động lượng sóng với Price Action & Volume", 
        "name": "Chương 3: [M]omentum - Động lượng sóng với Volume",
        "name_vi": "Chương 3: [M]omentum - Động lượng sóng với Volume",
        "name_en": "Chapter 3: [M]omentum - Price Momentum and Trading Volume",
        "name_cn": "第 3 章：[M]omentum - 具有交易量的动量波",
        isHaveTest: true,
        order: 3
      },
      { 
        "code": "Setup - Các mẫu hình Price Action & Volume", 
        "name": "[S]etup - Các mẫu hình VSA",
        "name_vi": "[S]etup - Các mẫu hình VSA",
        "name_en": "Chapter 4: [S]etup - VSA Setups",
        "name_cn": "[S]etup - VSA  模式",
        isHaveTest: true,
        order: 4
      },
      { 
        "code": "Ứng dụng kết hợp [S]tructure - [M]omentum - [S]etup vào giao dịch thực tế", 
        "name": "Phần BONUS: Ứng dụng kết hợp [S]tructure - [M]omentum - [S]etup vào giao dịch thực tế",
        "name_vi": "Phần BONUS: Ứng dụng kết hợp [S]tructure - [M]omentum - [S]etup vào giao dịch thực tế",
        "name_en": "BONUS Chapter: The SMS Model [S]tructure - [M]omentum - [S]etup in Trading",
        "name_cn": "BONUS部分：将[S]tructure-[M]omentum-[S]etup合并应用到实际交易中",
        isHaveTest: false,
        order: 5
      }
    ],
    'khoa-hoc-smc-tinh-gon': [
      { 
        "code": "Nền tảng phương pháp SMC", 
        "name": "Chương 1: Nền tảng phương pháp SMC",
        "name_vi": "Chương 1: Nền tảng phương pháp SMC",
        "name_en": "Chapter 1: SMC - Method Platform",
        "name_cn": "第 1 章: SMC方法平台",
        isHaveTest: true,
        order: 1
      },
      { 
        "code": "Xác định vùng Timing", 
        "name": "Chương 2: Xác định vùng Timing",
        "name_vi": "Chương 2: Xác định vùng Timing",
        "name_en": "Chapter 2: Determining the Timing zone",
        "name_cn": "第 2 章: 确定计时区",
        isHaveTest: true,
        order: 2
      },
      { 
        "code": "Thanh khoản Liquidity", 
        "name": "Chương 3. Thanh khoản Liquidity",
        "name_vi": "Chương 3: Thanh khoản Liquidity",
        "name_en": "Chapter 3: Liquidity",
        "name_cn": "第 3 章: 流动性",
        isHaveTest: true,
        order: 3
      },
      { 
        "code": "Ứng dụng SMC thực chiến theo từng khung thời gian", 
        "name": "Chương 4: Ứng dụng SMC thực chiến theo từng khung thời gian",
        "name_vi": "Chương 4: Ứng dụng SMC thực chiến theo từng khung thời gian",
        "name_en": "Chapter 4: Real-time SMC application for each time frame",
        "name_cn": "第 4 章: 每个时间范围的实时 SMC 应用",
        isHaveTest: false,
        order: 4
      }
    ],
    'khoa-hoc-supply-demand': [
      { 
        "code": "Nền tảng Supply và Demand", 
        "name": "Chương 1: Nền tảng Supply và Demand",
        "name_vi": "Chương 1: Nền tảng Supply và Demand",
        "name_en": "Chapter 1: Nền tảng Supply và Demand",
        "name_cn": "第 1 章: Nền tảng Supply và Demand",
        isHaveTest: true,
        order: 1
      },
      { 
        "code": "Kiến thức nâng cao Supply và Demand", 
        "name": "Chương 2: Kiến thức nâng cao Supply và Demand",
        "name_vi": "Chương 2: Kiến thức nâng cao Supply và Demand",
        "name_en": "Chapter 2: Kiến thức nâng cao Supply và Demand",
        "name_cn": "第 2 章: Kiến thức nâng cao Supply và Demand",
        isHaveTest: true,
        order: 2
      },
      { 
        "code": "Kết hợp tất cả cho một giao dịch hoàn hảo trong Forex & Crypto", 
        "name": "Chương 3. Kết hợp tất cả cho một giao dịch hoàn hảo trong Forex & Crypto",
        "name_vi": "Chương 3: Kết hợp tất cả cho một giao dịch hoàn hảo trong Forex & Crypto",
        "name_en": "Chapter 3: Kết hợp tất cả cho một giao dịch hoàn hảo trong Forex & Crypto",
        "name_cn": "第 3 章: Kết hợp tất cả cho một giao dịch hoàn hảo trong Forex & Crypto",
        isHaveTest: false,
        order: 3
      }
    ],
    'khoa-hoc-wyckoff-2': [
      { 
        "code": "Các khái niệm Phương pháp Wyckoff cổ điển", 
        "name": "Chương 1: Các khái niệm Phương pháp Wyckoff cổ điển",
        "name_vi": "Chương 1: Các khái niệm Phương pháp Wyckoff cổ điển",
        "name_en": "Chapter 1: Các khái niệm Phương pháp Wyckoff cổ điển",
        "name_cn": "第 1 章: Các khái niệm Phương pháp Wyckoff cổ điển",
        isHaveTest: true,
        order: 1
      },
      { 
        "code": "Wyckoff 2.0", 
        "name": "Chương 2: Wyckoff 2.0",
        "name_vi": "Chương 2: Wyckoff 2.0",
        "name_en": "Chapter 2: Wyckoff 2.0",
        "name_cn": "第 2 章: Wyckoff 2.0",
        isHaveTest: true,
        order: 2
      },
      { 
        "code": "Hồ sơ Khối lượng (Volume Profile) và các công cụ hỗ trợ", 
        "name": "Chương 3. Hồ sơ Khối lượng (Volume Profile) và các công cụ hỗ trợ",
        "name_vi": "Chương 3: Hồ sơ Khối lượng (Volume Profile) và các công cụ hỗ trợ",
        "name_en": "Chapter 3: Hồ sơ Khối lượng (Volume Profile) và các công cụ hỗ trợ",
        "name_cn": "第 3 章: Hồ sơ Khối lượng (Volume Profile) và các công cụ hỗ trợ",
        isHaveTest: true,
        order: 3
      },
      { 
        "code": "Ứng dụng Wyckoff 2.0 vào thực chiến", 
        "name": "Chương 4: Ứng dụng Wyckoff 2.0 vào thực chiến",
        "name_vi": "Chương 4: Ứng dụng Wyckoff 2.0 vào thực chiến",
        "name_en": "Chapter 4: Ứng dụng Wyckoff 2.0 vào thực chiến",
        "name_cn": "第 4 章: Ứng dụng Wyckoff 2.0 vào thực chiến",
        isHaveTest: false,
        order: 4
      }
    ],
    'khoa-hoc-snd-2.0': [
      { 
        "code": "Những vấn đề cốt lõi của Supply Demand 2.0", 
        "name": "Chương 1: Những vấn đề cốt lõi của Supply Demand 2.0",
        "name_vi": "Chương 1: Những vấn đề cốt lõi của Supply Demand 2.0",
        "name_en": "Chương 1: Những vấn đề cốt lõi của Supply Demand 2.0",
        "name_cn": "Chương 1: Những vấn đề cốt lõi của Supply Demand 2.0",
        isHaveTest: true,
        order: 1
      },
      { 
        "code": "Các vị thế giao dịch và cách lựa chọn một zone giá trị", 
        "name": "Chương 2: Các vị thế giao dịch và cách lựa chọn một zone giá trị",
        "name_vi": "Chương 2: Các vị thế giao dịch và cách lựa chọn một zone giá trị",
        "name_en": "Chương 2: Các vị thế giao dịch và cách lựa chọn một zone giá trị",
        "name_cn": "Chương 2: Các vị thế giao dịch và cách lựa chọn một zone giá trị",
        isHaveTest: true,
        order: 2
      },
      { 
        "code": "Các ví dụ vào lệnh tổng hợp theo Supply Demand 2.0", 
        "name": "Chương 3: Các ví dụ vào lệnh tổng hợp theo Supply Demand 2.0",
        "name_vi": "Chương 3: Các ví dụ vào lệnh tổng hợp theo Supply Demand 2.0",
        "name_en": "Chương 3: Các ví dụ vào lệnh tổng hợp theo Supply Demand 2.0",
        "name_cn": "Chương 3: Các ví dụ vào lệnh tổng hợp theo Supply Demand 2.0",
        isHaveTest: false,
        order: 3
      },
    ]
  }

  if (tradingAcademySlug === 'ALL') {
    return Object.values(partByAcademy).flat();
  }

  if (tradingAcademySlug === 'ALL_SLUG') {
    return Object.keys(partByAcademy);
  }
  
  return partByAcademy[tradingAcademySlug];
};

module.exports = { getParts };
