import LogoVnrWhite from "@components/Icons/logoVnrWhite";
import { serviceNameMapper } from "@components/ProfileUpdateInfo/ProductRegisterForm";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: {
    title: 'VnRebates - Kiến thức Trading chuyên sâu Forex, Crypto, Hàng hoá',
    description: 'VnRebates cung cấp dịch vụ hoàn phí hàng đầu tại Việt Nam và khu vực châu Á với các broker uy tín, đồng thời cung cấp các khóa học trading, kênh tín hiệu miễn phí',
    logo: '/icons/logoVnrHomePage.svg',
    logoWhiteFooter: <LogoVnrWhite width={200} />,
    logoWhiteFooterMobile: <LogoVnrWhite width={150} />,
    forexBrokerListTitle: 'Sàn Forex có hoàn phí',
    tradeFundListTitle: 'Quỹ giao dịch',
    cryptoBrokerListTitle: 'Sàn Crypto có hoàn phí',
    viewMore: 'Xem tất cả',
    topBrokerPost: 'Top Broker List',
    paymentMethod: 'REBATES PAYMENT METHOD',
    homeSliderForeign: '',
    bannerAcademy: "https://d3gxgptuxgz1ri.cloudfront.net/bannerAds/web%20banner%20airdrop%20thang%2010%20lan%203%20v2.jpg",
    bannerSignal: "https://d3gxgptuxgz1ri.cloudfront.net/bannerAds/z5908520635440_73ae91dd6b590b233fe9ee0f2ffcbf68.jpg"
  },
  dashboard: {
    rewardTreeTitle: 'Lợi ích khi là thành viên VnRebates',
    title: 'Lợi ích khi là thành viên VnRebates',
    hello: 'Hello',
    friend: 'bạn',
    totalRebate: 'Tổng hoàn phí đã nhận:',
    rebate: 'Hoàn phí:',
    ctv: 'Trở thành CTV và nhận thêm 50% hoa hồng',
    quote: ' VnRebates mong muốn đồng hành cùng Kiến tạo giá trị và lợi nhuận bền vững trên con đường Trader của bạn',
    benefitDescription: 'Và tất cả Thành viên VnRebates đều sẽ nhận được các lợi ích này theo từng chặng đường đồng hành bên dưới:',
    rewardPathTitle: 'Quà tặng tích lũy',
    reward: 'Quà tặng',
    goal: 'Mục tiêu & Hành động',
    titleRebateLink: 'Quản lý tài khoản Hoàn phí của tôi',
    linkedHistory: 'Lịch sử liên kết',
    noLink: 'Chưa có liên kết nào',
    addBrokerAccountSuccess: 'Thêm liên kết thành công',
    pleaseSelectBroker: 'Vui lòng chọn.',
    selectBroker: 'Chọn broker',
    accountNumber: 'Số tài khoản',
    addLink: 'Thêm liên kết',
    confirm: 'Tôi đồng ý cho phép cài đặt chủ sở hữu VnRebates là IB của tôi',
    paymentMethod: 'Phương thức nhận Hoàn phí',
    caution: 'Lưu ý',
    cautionNote1: 'VnRebates chỉ hỗ trợ hoàn phí cho các tài khoản đã yêu cầu liên kết thành công.',
    cautionNote2: 'Tài khoản phải được điền đầy đủ thông tin cá nhân và phương thức thanh toán trước ngày 5 thì mới được thanh toán hoàn phí.',
    cautionNote3: 'Các khoản hoàn phí sẽ không được cộng dồn qua tháng nếu trước đó bạn chưa điền đầy đủ thông tin.',
    payRebatesDescription: `Tiền Rebates (Hoàn phí Forex) sẽ được thanh toán tự động cho bạn trước
    ngày 5 hàng tháng cho tháng trước đó.`,
    lastMonthRebate: 'Hoàn phí tháng',
    totalReceivedCommission: 'Tổng hoa hồng đã nhận',
    totalReceivedRebates: 'Tổng hoàn phí đã nhận',
    statistical: 'Thống kê tiền Rebates 12 tháng vừa qua',
    walletEmail: 'email ví ngân lượng',
    agreed: 'Đồng ý',
    agencyRegisterTitle: 'Cộng tác viên giới thiệu Khách hàng',
    agencyRegister: 'Đăng ký Cộng tác viên',
    agencyRegisterParterRequired: 'Bạn cần đăng nhập để kích hoạt tính năng này',
    activities: 'Hoạt động hoàn phí',
    noActivitives: 'Chưa có hoạt động nào',
    bannerRebates: "https://d3gxgptuxgz1ri.cloudfront.net/thumbnail/web-dashboard%20webp.webp",
    agencyViewStat: 'Xem thống kê khách hàng và hoa hồng của bạn',
    agencyLink: 'Link giới thiệu',
    copyAgencyLink: 'Copy link',
    agencyCode: 'Mã giới thiệu',
    traderPath: 'Con đường Trader',
    manageRebates: 'Quản lí Rebates',
    lastMonthCompare: 'for last month',
    ctvMobile: 'Đăng ký CTV nhận 50% hoa hồng',
    activeDescription: 'Đã thanh toán Rebates',
    updateAccount: 'Cập nhật tài khoản'
  },
  homepageBrokerList: {
    openAccount: 'Mở tài khoản',
    rebateRate: 'Hoàn phí',
    viewMore: 'Xem tất cả',
    feeReduction: 'Tỷ lệ giảm phí',
    noted: 'Ghi chú',
    exchange: 'Sàn phi tập trung',
    maxLeverage: 'Đòn bẩy',
    transactionFees: 'Phí giao dịch'
  },
  avatarDropdown: {
    profile: 'THÔNG TIN CÁ NHÂN',
    dashboard: 'DASHBOARD',
    logout: 'ĐĂNG XUẤT'
  },
  menu: {
    featurePost: 'BÀI VIẾT NỔI BẬT',
    topicTreeName: 'CÂY KIẾN THỨC FOREX',
    loginButton: 'Đăng nhập',
    dashboard: 'Dashboard',
    logout: 'Đăng xuất',
    profile: 'Thông tin cá nhân',
    signalChanel: 'KÊNH TÍN HIỆU',
    forex: 'FOREX'
  },
  layout: {
    breadcrumb: 'Trang chủ'
  },
  brokerList: {
    forex: 'DANH SÁCH CÁC SÀN FOREX UY TÍN, HOÀN PHÍ',
  },
  radioBroker: {
    note: 'Khi tham gia mở tài khoản và nhận hoàn phí tại các Broker/Công ty môi giới bên dưới, VnRebates cam kết mọi điều kiện giao dịch giống như khi bạn mở trực tiếp',
    forex: 'Forex Broker',
    crypto: 'Crypto Broker',
    fund: 'Quỹ giao dịch',
    securities: 'Công ty chứng khoán',
    merchandise: 'Công ty giao dịch hàng hoá',
  },
  forexInfo: {
    reviewPost: 'Xem đánh giá sàn',
    guild: 'Hướng dẫn Nạp/Rút tiền tại sàn',
    rebateRate: 'Tỉ lệ hoàn phí',
    viewDetail: 'Xem chi tiết',
    gold: 'Vàng',
    fx: 'Ngoại tệ',
    oil: 'Dầu',
    index: 'Chỉ số',
    crypto: 'Crypto',
    receiveRebates: 'Nhận hoàn phí',
    register: 'Đăng ký',
    establish: 'Năm thành lập',
    regulated: 'Quản lý bởi',
    viewRegulated: 'Tổ chức quản lý',
    model: 'Loại hình',
    deposit: 'Nạp tiền',
    minDeposit: 'Nạp tối thiểu',
    minVolume: 'Khối lượng tối thiểu',
    maxLeverage: 'Đòn bẩy tối đa',
    spread: 'Spread',
    product: 'Sản phẩm',
    fee: 'Phí giao dịch',
    openAccount: 'Mở tài khoản',
    pageTitle: 'Danh sách sàn Forex uy tín, có giấy phép và hoàn phí tại Việt Nam - VnRebates',
    pageDescription: 'Đánh giá các sàn Forex uy tín (Forex Broker) hàng đầu trên thế giới đầy đủ nhất và tỷ lệ hoàn phí tốt nhất gồm sàn Exness, ICmarkets, XM, XTB,... từ VnRebates'
  },
  cryptoPage: {
    title: 'Danh sách các sàn Crypto uy tín - VnRebates',
    description: 'Danh sách các sàn Crypto Broker uy tín với thông tin đầy đủ nhất và ưu đãi giảm phí giao dịch tốt nhất'
  },
  tradeFund: {
    title: 'Danh sách quỹ Forex uy tín trên thế giới - VnRebates',
    description: 'Thông tin chi tiết về các Quỹ giao dịch Forex uy tín với chi phí thấp và mức tỷ lệ phân chia lợi nhuận tốt nhất từ VnRebates',
    reviewPost: 'Đánh giá và review quỹ',
    year: 'năm',
    payoutRatio: 'Tỉ lệ lợi nhuận trader',
    allowBalance: 'Mức cấp vốn',
    subscribeFee: 'Phí tham gia',
    dailyDrawdown: 'Daily Drawdown',
    maxDrawdown: 'Max Drawdown',
    newsTrading: 'Giao dịch theo tin',
    demo: 'Demo',
    vietnameseSupport: 'Hỗ trợ tiếng Việt'
  },
  slickBroker: {
    text: 'Mở TK và hoàn phí'
  },
  footer: {
    social: 'MẠNG XÃ HỘI',
    category: 'DANH MỤC',
    riskDisclaimer: 'Cảnh báo rủi ro: Việc giao dịch Forex, Futures và Options mang lại lợi nhuận cao nhưng cũng kèm theo không ít rủi ro tiềm ẩn. Đòn bẩy cao có thể gây thiệt hại nhiều như khi nó mang lại lợi nhuận cho bạn vậy. Bạn phải chú ý những rủi ro này và phải sẵn sàng chấp nhận nó khi giao dịch. Giao dịch forex mang rủi ro cao và không thích hợp với tất cả nhà đầu tư. Chúng tôi chỉ cung cấp thông tin tiền tệ đến các nhà đầu tư. Và hãy nhớ rằng một kết quả tốt trong quá khứ của bất kỳ hệ thống hay phương pháp giao dịch nào cũng không đảm bảo sẽ mang lại kết quả tốt trong tương lai',
    license: `Giấy phép đăng kí: Mạng xã hội thử nghiệm đang trong quá trình hoàn thành thủ tục cấp giấy phép` ,
    advertising: 'FOR ADVERTISING',
    copyRight: 'COPYRIGHT',
    copyRightReserve: '. ALL RIGHTS RESERVED'
  },
  awardTarget: {
    becameMember: 'Trở thành thành viên VnRebates',
    awardAchieved: 'Đã hoàn thành',
    login: 'Đăng nhập',
    startHere: 'Bắt đầu tại đây',
    airTagRequire: 'Đạt 1,000$ hoàn phí',
    airPodRequire: 'Đạt 4,000$ hoàn phí',
    smartWatchRequire: 'Đạt 14,000$ hoàn phí',
    macbookRequire: 'Đạt 40,000$ hoàn phí',
    shRequire: 'Đạt 100,000$ hoàn phí'
  },
  profile: {
    title: 'Điền thông tin chính xác để nhận ngay các "Khuyến mãi lần đầu" dành cho các thành viên mới của VnRebates (Thời gian để nhận 24h kể từ lúc đăng ký)',
    name: 'Họ tên',
    phone: 'Số điện thoại',
    email: 'Email',
    province: 'Tỉnh',
    servicesQuestion: 'Chọn Khuyến mãi bạn muốn nhận',
    martkets: 'Thị trường quan tâm',
    buttonText: 'ĐĂNG KÝ',
    guildText: 'Đăng ký nhận "KHUYẾN MÃI LỚN" dành cho "KHÁCH HÀNG LẦN ĐẦU"'
  },
  videoAcademy: {
    breadcrumbAcademy: 'Khóa học',
    title: 'Khóa học VSA & Volume Master - Bơi cùng cá mập',
    description: 'Khóa học VSA & Volume Master - Bơi cùng cá mập được VnRebates biên tập và tổng hợp dựa trên kiến thức, kinh nghiệm, phân tích giao dịch từ các chuyên gia trader có uy tín của VnRebates.',
    priceText: 'Giá khóa học',
    price: '200$ (4.600.000 VNĐ)',
    paymentMethod: 'Phương thức thanh toán',
    banking: 'Chuyển khoản ngân hàng',
    purchase: 'Học ngay',
    viewing: 'Đang xem',
    viewed: 'Đã xem',
    free: 'Miễn phí',
    view: 'Xem',
    test: 'Bài kiểm tra chương',
    chatSupport: 'Chat support ngay',
    supportOpenAcademy: 'Nhận Khóa học Video Miễn Phí'
  },
  logoSponsor: {
    text: 'Khóa học được đồng tài trợ bởi Broker:',
    openAccount: 'Mở tài khoản'
  },
  courseInAcademy: {
    title: 'HỆ THỐNG KIẾN THỨC',
    nameCourse1: "VSA & VOLUME MASTER",
    nameCourse2: "SMC TINH GỌN",
    nameCourse3: "SUPPLY & DEMAND",
    nameCourse4: "WYCKOFF 2.0",
    nameCourse5: "SND 2.0",
    leveCourse1: `Bơi cùng cá mập`,
    leveCourse2: "Truy vết Dòng tiền thông minh",
    leveCourse3: "Hiểu về LỰC mới dụng được LỰC",
    leveCourse4:  "Sự thực dụng của Wyckoff hiện đại",
    leveCourse5:  "Dụng lực đúng vùng, giá đi tức thời",
    indexCourse1: "Khoá video 1",
    indexCourse2: "Khóa video 2",
    // indexCourse3: "Khóa video 3",
    indexCourse4: "Khoá video 3",
    indexCourse5: "Khoá video 4"
  },
  popupLogin: {
    benefitTitle: `"Đăng nhập vào VnRebates, bạn có thể nhận được các Tiện ích sau"`,
    // benefitName1: 'Nhận 4 Khoá học về Trading cơ bản tới nâng cao (Price Action, Tâm lý & quản lý vốn, Volume & VSA)',
    benefitName1: serviceNameMapper['Khóa Video 1 - VSA & Volume Master (200$)'],
    benefitName2: serviceNameMapper['Khóa Video 2 - SMC tinh gọn (200$)'],
    benefitName3: serviceNameMapper['Khóa Video 3 - Supply & Demand 2.0 (200$)'],
    benefitName4: serviceNameMapper['Dịch vụ Hoàn phí'],
    or: 'hoặc',
    textBack: 'Quay lại Đăng nhập',
    login: 'Đăng nhập',
    register: 'Đăng ký',
    loginEmail: 'Email đăng nhập',
    loginPassword: 'Mật khẩu đăng nhập',
    loginRePassword: 'Nhập lại mật khẩu',
    agencyCode: 'Mã của môi giới',
    registerAccount: 'Đăng kí tài khoản',
    forgotPassword: 'Quên mật khẩu?',
    checkboxText: 'Tôi đồng ý nhận email thông báo dịch vụ, khuyến mãi và nội dung giáo dục của VnRebates'
  },
  popupRegisterAgency: {
    title: 'ĐIỀU KHOẢN TRỞ THÀNH “CỘNG TÁC VIÊN MÔI GIỚI VNREBATES”',
    agreed: 'Đồng ý với các điều khoản sau để trở thành cộng tác viên tại VnRebates',
    benefit: '1. Quyền lợi:',
    benefit1: 'Nhận hoa hồng theo tỷ lệ 50% số tiền hoàn của tất cả các khách hàng mà CTV giới thiệu nhận được',
    benefit2: 'Hoa hồng sẽ được thanh toán cùng với số tiền hoàn mà CTV nhận được trước ngày 5 hàng tháng',
    benefit3: 'Được cấp sử dụng hình ảnh, link ref, tài nguyên, dịch vụ của VnRebates để quảng bá, tiếp thị tới thị trường và khách hàng tiềm năng',
    benefit4: 'Được hỗ trợ thông tin, kiến thức về chương trình mới của các Broker, các loại tài khoản, tỷ lệ hoàn phí…',
    benefit5: 'Khách hàng có quyền chuyển mã giới thiệu của CTV, mã giới thiệu và hoa hồng cho CTV mới sẽ có hiệu lực trong tháng tiếp theo.',
    responsibilities: '2. Nghĩa vụ:',
    responsibilities1: 'Chủ động quảng bá, giới thiệu dịch vụ hoàn phí cho các dịch vụ tài chính của VnRebates đến thị trường và các khách hàng.',
    responsibilities2: 'Sử dụng đúng những thông tin về dịch vụ mà VnRebates cung cấp để tư vấn giới thiệu cho khách hàng.',
    responsibilities3: 'Không được dụng hình ảnh, danh nghĩa của VnRebates cho các hành vi vi phạm pháp luật, hoặc ảnh hưởng xấu đến uy tín và thương hiệu của VnRebates.',
    responsibilities4: 'Chịu trách nhiệm chính trong việc giải quyết các vấn đề phát sinh giữa Khách hàng với Broker.',
    ourResponsibilities: '3. Trách nhiệm của VnRebates:',
    ourResponsibilities1: 'Chi trả tiền hoàn cho khách hàng và cộng tác viên theo quy định về hoa hồng của từng Broker mà VnRebates nhận được thực tế.',
    ourResponsibilities2: 'VnRebates là cầu nối hỗ trợ giải quyết các vấn đề phát sinh giữa khách hàng, cộng tác viên và Broker.',
    ourResponsibilities3: 'VnRebates không chịu trách nhiệm ( bao gồm nhưng không giới hạn) các vấn đề giữa khách hàng và các Broker được giới thiệu.',
    termination: '4. Dừng hợp tác:',
    terminationText: 'Khi CTV vi phạm các điều khoản, gây ảnh hưởng tới quyền lợi của khách hàng, quyền lợi và hình ảnh của VnRebates',
    yes: 'Tôi đồng ý',
    no: 'Không'
  },
  tutorialTranferIBModal: {
    title: 'Đã gửi thông tin tài khoản',
    text1: 'Cảm ơn bạn! VnRebates đã nhận được thông tin tài khoản và broker để xác thực liên kết hoàn phí. ',
    text2: 'Tiếp theo, bạn vui lòng inbox support bên dưới để hoàn tất quá trình xác thực nhanh chóng và tiện lợi.',
    support: 'Telegram Support'
  },
  buttonTelegram: 'Chat với Support',
  roadToPro: {
    title: 'Khóa học Forex giúp bạn trở thành Pro Trader - VnRebates',
    description: 'Con đường trở thành Pro Trader sẽ dễ dàng hơn với khóa học Forex được biên soạn bởi đội ngũ giàu kinh nghiệm đầu tư Forex và các kênh tài chính khác của VnRebates.'
  },
  rebatePaymentMethod: {
    signUpButton: 'SIGNUP FOR FREE',
    nganluongWallet: 'Ngân lượng Wallet',
    usdtWallet: 'USDT Wallet',
    direct: 'Direct to Trading Account'
  },
  referrals: {
    seoTitle: 'Chương trình Referrals - Giới thiệu bạn bè và hợp tác IB cùng VnRebates',
    title: 'Chương trình: "Giới thiệu 1 Khách hàng - Hoàn phí cả 2"',
    description: 'Kiếm thu nhập thụ động ổn định bằng cách giới thiệu khách hàng sử dụng dịch vụ hoàn phí tại VnRebates trong các lĩnh vực Forex, Crypto, Chứng khoán, Hàng hóa phái sinh.',
    start: 'Bắt đầu ngay',
    titleBenefit: 'Chương trình giới thiệu khách hàng hoạt động như thế nào?',
    roleDescription: 'Giới thiệu khách hàng cho VnRebates bằng cách sử dụng <b>LIÊN KẾT GIỚI THIỆU RIÊNG</b> của bạn và bạn sẽ nhận hoa hồng <b>TIỀN MẶT</b> dựa trên các giao dịch của họ và những giao dịch mà họ giới thiệu <b>TRỌN ĐỜI</b>, chỉ cần tài khoản của họ còn hoạt động.',
    referralFriends: 'BẠN SẼ NHẬN ĐƯỢC BAO NHIÊU TIỀN?',
    firstReason1: 'Khách hàng được bạn giới thiệu: Nhận được 40% hoàn phí từ phần backcom mà Broker chi cho VnRebates.',
    firstReason2: 'Nhận tiền mặt 50% số tiền hoàn phí từ những khách hàng mà bạn giới thiệu nhận được',
    firstReason3: 'Nhận tiền mặt 8.75% số tiền hoàn phí từ những khách hàng được người bạn giới thiệu giới thiệu nhận được',
    referralFriendsHow: 'BẠN SẼ NHẬN HOA HỒNG NHƯ THẾ NÀO?',
    secondReason1: 'VnRebates sẽ thống kê tổng số tiền hoa hồng giới thiệu mà bạn nhận được của tháng, cập nhật trước ngày 5 tháng tiếp theo',
    secondReason2: 'Số tiền hoa hồng sẽ được thanh toán kèm với tiền hoàn phí mà bạn nhận được từ việc giao dịch của chính bạn trước ngày 5 tháng tiếp theo',
    secondReason3: 'Phương thức thanh toán: Ví ngân lượng',
    criteriaWrapper: 'Tiêu chí chọn broker liên kết của VnRebates',
    criteriaList: 'Các Broker Quốc tế dưới các tổ chức quản lý uy tín như: FCA, FSA...',
    criteriaRequire: 'Điều kiện giao dịch ưu đãi cho nhiều loại tài khoản phù hợp với nhiều phong cách giao dịch của khách hàng, đặc biệt là các Pro Trader',
    criteriaTranslate: 'Có đội ngũ hỗ trợ tiếng Việt chuyên nghiệp, tận tâm',
    guildTitle: 'Hướng dẫn đăng ký giới thiệu khách hàng',
    register: 'Đăng ký thành viên cùng VnRebates',
    registerGuild: 'Bấm vào nút <strong>“Đăng nhập”</strong> phía góc phải trên cùng để trở thành Thành viên của VnRebates',
    login: 'Đăng nhập',
    agencyRegister: 'Đăng ký trở thành CTV giới thiệu khách hàng tại VnRebates',
    agencyRegisterGuild: 'Tại trang cá nhân sau khi đăng nhập xong, bạn kéo xuống khu vực Cộng tác viên giới thiệu khách hàng và click vào nút "Dăng ký Cộng tác viên" để trở thành cộng tác viên giới thiệu khách hàng và lấy link và mã giới thiệu của bạn.',
    step3: 'Tiến hành giới thiệu và nhận hoa hồng',
    step3Guild: 'Sử dụng link hoặc mã giới thiệu riêng của bạn để giới thiệu khách hàng sử dụng dịch vụ hoàn phí của VnRebates để nhận hoa hồng.',
    step3GuildMore: 'Theo dõi số khách hàng và hoa hồng hàng tháng theo link thống kê',
    here: 'tại đây',
    step3GuildMoreAndMore: 'Đăng ký thông tin ví ngân lượng để nhận hoa hồng',
    step4: 'Bắt đầu giới thiệu bạn bè',
    contact: 'Mọi thắc mắc xin hãy liên hệ chúng tôi',
    principle: 'Kim chỉ nam hoạt động của VnRebates',
    principleQuote: 'Muốn đi nhanh thì đi một mình, muốn đi xa thì đi cùng nhau',
    principleEnding: 'Chúng tôi luôn muốn kết nối cộng đồng IB, hợp tác tạo giá trị và mong muốn mang lại lợi nhuận BỀN VỮNG cho khách hàng của các bạn.'
  },
  brokerOpenAccountInstruction: {
    step1: 'Click vào nút bên dưới để Mở tài khoản',
    step2: `Gửi tin nhắn qua 
    <a href="https://www.facebook.com/Vnrebates" target="_blank"> Fanpage </a>
    hoặc <a href="https://t.me/vnrebatessupport" target="_blank">Support Telegram </a>
    để xác nhận`,
    step3: 'Bắt đầu Giao dịch & nhận Hoàn phí hàng tháng từ VnRebates',
    fund: `Gửi tin nhắn qua 
    <a href="https://www.facebook.com/Vnrebates" target="_blank"> Fanpage </a>
    hoặc <a href="https://t.me/vnrebatessupport" target="_blank">Support Telegram </a>
    để tư vấn Free chọn quỹ phù hợp`
  },
  landingPageRebates: {
    seoTitle: 'Dịch vụ Hoàn phí Forex hấp dẫn từ VnRebates',
    header: `Bạn có biết <b>80% TRADER</b>`,
    subHeader: 'được giới thiệu qua IB của họ',
    headerContent1: `Nhưng <b>KHÔNG</b> nhận được gì từ tiền hoa hồng đó`,
    headerContent2: `Hãy <b>DỪNG NGAY</b> việc bị mất tiền này lại`,
    readMore: 'Tìm hiểu thêm',
    subHeaderMobile: `được giới thiệu qua IB của họ`,
    mobileHeaderContent1: `Nhưng <b>KHÔNG</b> nhận được gì <br /> từ tiền hoa hồng đó`,
    mobileHeaderContent2: `Hãy <b>DỪNG NGAY</b> việc bị mất <br /> tiền này lại`,
    titleSection: `Dịch vụ <b>Hoàn phí giao dịch tài chính</b> (Rebates) là gì? `,
    sectionText1: 'Đơn giản nhất, tức là Khi bạn mở tài khoản giao dịch tại các Broker về giao dịch chứng khoán, hàng hóa, forex, crypto thông qua IB của VnRebates, chúng tôi sẽ nhận được hoa hồng từ Broker khi bạn giao dịch.',
    sectionText2: `Sau đó, chúng tôi trích lại <b>lên tới 80%</b> từ Hoa hồng này ngược lại cho các bạn thì được gọi là Hoàn phí (tiếng anh là Rebates)`,
    titleSection1: 'Điều này nghĩa là gì? Tức là',
    itemList1: 'Giao dịch càng nhiều - Nhận tiền càng nhiều',
    itemList2: 'Tạo thêm nguồn thu nhập thụ động',
    itemList3: 'Điều kiện giao dịch 100% như mở trực tiếp tại Broker',
    itemList4: 'Bù đắp chi phí giao dịch',
    itemList5: 'Hoàn phí dựa vào Khối lượng giao dịch - Dù lệnh thắng hay thua',
    itemList6: 'Hoàn phí cho tất cả các giao dịch Chứng khoán, hàng hóa, forex, crypto',
    brokerRebates: 'Tỷ lệ Hoàn phí tại các Broker',
    brokerRebatesDescription: 'Danh sách đầy đủ các sàn hỗ trợ Hoàn Phí',
    forexList: 'Danh sách Forex',
    cryptoList: 'Danh sách Crypto',
    securitiesList: 'Danh sách Chứng khoán',
    merchandiseList: 'Danh sách Hàng hóa',
    titleSection2: 'Đăng ký Nhận hoàn phí (Rebates)',
    step1: 'Đăng ký trở thành thành viên của VnRebates',
    step2: ` Bấm vào nút <b>“Đăng nhập”</b> phía góc phải trên cùng để trở thành Thành viên của VnRebates`,
    login: 'Đăng nhập',
    step3: 'Mở tài khoản giao dịch',
    step3Guild: 'Lựa chọn sàn giao dịch phù hợp và Mở tài khoản giao dịch tạidanh sách Broker dưới đây',
    brokerList: 'Danh sách Brokers',
    step4: 'Yêu cầu nhận Hoàn phí',
    step4Guild: 'Sau khi mở tài khoản tại 1 sàn giao dịch và xác minh danh tính. Bạn hãy truy cập lại vào mục Dashboard bằng cách nhập vào ảnh đại diện',
    step4Guild1: 'Yêu cầu nhận hoàn phí tại sàn bạn mong muốn và đợi chúng tôi xác nhận yêu cầu',
    step5: 'Thêm phương thức thanh toán',
    step5Guild: 'Hiện VnRebate chỉ hỗ trợ thanh toán qua Ngân Lượng, vì vậy nếu bạn chưa có tài khoản hãy đăng ký tài khoản tại ví điện tử Ngân Lượng',
    here: 'tại đây',
    emailMethod: 'Điền thông tin email vào mục phương thức thanh toán',
    step6: 'Bắt đầu giao dịch',
    step6Guild: `Sau khi hoàn thành các bước, bạn có thể bắt đầu giao dịch. <br />
    VnRebates sẽ tổng hợp và tiến hành thanh toán khoản Hoàn phí cho
    bạn trước ngày 5 hàng tháng`,
    step6Guild1: `Chúng tôi sẽ Hoàn phí dù bạn mới <strong>Mở tài khoản</strong>
    hoặc <strong>Đã có tài khoản</strong> tại các sàn trên`,
    contact: 'Mọi thắc mắc, xin hãy liên hệ chúng tôi',
    fanpage: 'Hỗ trợ Fanpage',
    telegram: 'Hỗ trợ Telegram',
    trustUs: 'Chúng tôi muốn cung cấp đến các Trader Việt Nam dịch vụ Hoàn tiền chuyên nghiệp nhất, là cách thức mà lợi ích của bạn chính là lợi ích của chúng tôi',
    trustUs1: 'Nó hoàn toàn Miễn phí, bạn nhận được thêm tiền, và chúng tôi luôn muốn bạn chiến thắng để tôi có thể đồng hành lâu dài hơn với bạn.',
    brokerAccount: 'Tài khoản giao dịch tại Broker',
    product: 'Sản phẩm',
    currency: 'Tiền tệ (EUR/USD)',
    gold: 'Vàng',
    derivative: 'Phái sinh',
    merchandise: 'Hàng hóa (Cá phê, dầu,...)',
    derivativeSecurities: 'Chứng khoán (phái sinh)',
    volume: 'Volume giao dịch/tháng',
    unit: 'Đơn vị tính',
    contract: 'Hợp đồng',
    spreadRate: 'Tỷ lệ share spread/phí',
    spread: 'Spread / phí (VNĐ)',
    vnrCommission: 'Phí môi giới VnRebates nhận được',
    sum: 'Tổng cộng',
    customerCommission: 'Hoàn phí khách hàng nhận được',
    trustedUs: 'Trusted us',
    exnessCurrency: '8.510.000',
    binance: '2.760.000',
    vps: '640.000',
    sumTotal: '20.420.000',
    sumRebates: '8.168.000',
    spreadCurrencyExness: '2.300.000',
    spreadGoldExness: '4.600.000'
  },
  popupTradingAcademyVideo: {
    buy: 'Để mua ngay hoặc nhận FREE',
    pleaseInbox: 'vui lòng inbox support bên dưới',
    title: 'MUA NGAY KHÓA HỌC VIDEO'
  },
  controlFooter: {
    prevLesson: 'BÀI TRƯỚC ĐÓ',
    nextLesson: 'BÀI TIẾP THEO'
  }
}